import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://cwamerchantservices.com/wp-content/uploads/2015/08/debit_card_23.jpg"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAa4qXaZ82J//xAAZEAEAAwEBAAAAAAAAAAAAAAACAAEDBBP/2gAIAQEAAQUCXTcWiUy2KCwXqeY1Ko1P/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAECEf/aAAgBAwEBPwFq6Fp//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACIf/aAAgBAgEBPwEcly//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRISIx/9oACAEBAAY/AsjSNZrpjUfDp2Yj/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARITFRcf/aAAgBAQABPyGvdxpZVLYewUlpbjBOhvUZzOD0IeT/2gAMAwEAAgADAAAAEK8f/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAwEBPxBTOQBi3//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESFB/9oACAECAQE/EBc9lTov/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIUExsVFxwf/aAAgBAQABPxBZbeMpfncbtdhfB9QD8wqsm4VQbcBR13Dj4iYIZLNEf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "debit_card_23",
            "title": "debit_card_23",
            "src": "/static/d5e7178b1c6548a7888ef783c84c9db3/8fc94/debit_card_23-300x220.jpg",
            "srcSet": ["/static/d5e7178b1c6548a7888ef783c84c9db3/8675c/debit_card_23-300x220.jpg 100w", "/static/d5e7178b1c6548a7888ef783c84c9db3/3cc80/debit_card_23-300x220.jpg 200w", "/static/d5e7178b1c6548a7888ef783c84c9db3/8fc94/debit_card_23-300x220.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h3>{`Americans are using debit cards more, especially for small dollar purchases.`}</h3>
    <p>{`They also make fewer withdrawals from ATMs than they did just a decade ago. These are two key trends reported in the 2015 Debit Issuer Study from Pulse, a network owned by Discover Financial Services.`}</p>
    <p>{`Meanwhile, the Federal Reserve has published a “clarification” of how it determined debit interchange caps when it was directed to do under the Durbin Amendment to the 2010 Dodd-Frank Act.`}</p>
    <p>{`The Fed has come under fire for years for a ruling it issued in 2011 that capped debit card interchange as directed by the Durbin Amendment. The National Association of Convenience Stores successfully argued before a U.S. district court that the cap set by the Fed – 21 cents per transaction plus an adjustment for fraud prevention – was too low.`}</p>
    <p>{`But the district court’s decision was overturned last year by the U.S. Court of Appeals for the District of Columbia Circuit. The appeals court, however, instructed the Fed to explain one aspect of the ruling, the treatment of fraud protection and prevention costs. That explanation, published recently in the Federal Register, makes distinctions between fraud tools used at the transaction and program levels.`}</p>
    <p>{`10-year track record`}</p>
    <p>{`Pulse is one of the oldest debit card networks in the nation and an early champion of POS debit. It commissions a yearly debit issuer study. The latest, the 2015 Debit Issuer Study, reveals that over the past 10 years, debit card usage has grown 32 percent.`}</p>
    <p>{`“The past decade saw a major shift in consumer preference to paying with debit cards, which accelerated after the 2008-2009 recession,” said Steve Sievert, Executive Vice President for marketing and communications at Pulse. “We believe there is still opportunity for debit growth, considering that consumer use of debit for smaller purchases is rising, and the average active debit card is used about five times per week.”`}</p>
    <p>{`Tony Hayes, a Partner at Oliver Wyman Group who co-led the study, said, "Consumer use of debit has been nothing short of remarkable. Debit has steadily gained wallet share as consumers shift their spending to this payment type. The use of debit for small-ticket purchases is particularly noteworthy, where one-third of all debit transactions are for less than $10 – purchases that historically would have been made with cash or not at all."`}</p>
    <p>{`Debit cards have been available for POS payments since the 1980s but only began to gain significant market share in the 1990s, when Visa Inc. and MasterCard Worldwide logos began appearing on debit cards, and the networks behind those logos began clearing debit card payments authorized at the POS by cardholder signatures. Debit card activity grew to equal credit card payments in the early 2000s, and about 10 years ago, yearly debit card tallies began to exceed those for credit cards.`}</p>
    <p>{`The credit crunch that began in 2008 contributed to some of this growth, as consumers postponed credit spending. So did growing adoption of prepaid debit cards. Now, the yearly total of PIN, signature and prepaid debit card spending is nearly double the total for credit card payments, according to Pulse. The average active debit cardholder conducted 23.2 debit card transactions (POS and ATM) in 2014, according to the study.`}</p>
    <h2>{`Weighing Durbin`}</h2>
    <p>{`Ten years ago financial institutions were receiving a weighted average interchange of 41 cents on a consumer debit card transaction. The Durbin Amendment – or more specifically, Federal Reserve Regulation II, which implemented the legislative mandate – changed that by capping debit card interchange at 21 cents plus a markup for fraud prevention. The cap, however, only applies to banks with $10 billion or more in assets.`}</p>
    <p>{`In 2014, debit card issuers subject to the caps earned a weighted average interchange of 24 cents on each card transaction, while exempt institutions earned 40 cents per transaction in interchange, according to the study.`}</p>
    <p>{`The drop in per-transaction earnings has been offset somewhat by growing usage, however. In 2008 (pre-Durbin) debit card issuers earned an average $81 in annual interchange per active debit cardholder, according to Pulse’s historical data. According to the latest study, exempt issuers generated an average of $112 per card in debit interchange revenue in 2014; the average was $59 per card for issuers subject to the Durbin debit caps.`}</p>
    <p>{`Here are further key data points from the Pulse report:`}</p>
    <ul>
      <li parentName="ul">{`Active cardholders averaged 16.1 POS debit transactions a month in 2005; in 2014 the average had jumped to 21.1 per month.`}</li>
      <li parentName="ul">{`In 2005, the average annual spend per active debit card was $7,807; in 2014 it was $9,291.`}</li>
      <li parentName="ul">{`The average debit card ticket was $40 in 2005; that dropped to $37 in 2014`}</li>
      <li parentName="ul">{`The average active cardholder made 3.4 ATM withdrawals month in 2005, but just 2.0 per month in 2014.`}</li>
    </ul>
    <p>{`For more information on Debit Cards, please contact CWA - Merchant Services`}</p>
    <p>{`Phone: (866) 210 - 4625 X1`}</p>
    <p>{`Email: `}<a parentName="p" {...{
        "href": "mailto:merchantsupport@cwams.com"
      }}>{`merchantsupport@cwams.com`}</a></p>
    <p>{`Full article at `}<a parentName="p" {...{
        "href": "http://www.greensheet.com/breakingnews.php?flag=breaking_news"
      }}>{`Greensheet.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      